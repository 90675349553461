import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from "../auth/authGuard";



//Login and User registration
const LandingPageView  = () => import('../views/LandingPageView')
const LoggedinView  = () => import('../views/LoggedinView')
// Container
const TheContainer = () => import('../containers/TheContainer')
const NoPermissionView = () => import('../views/NoPermissionView')
const RecordingsView = () => import('../views/RecordingsView')
const DashboardView = () => import('../views/DashboardView')
const RecordingSetView = () => import('../views/RecordingSetView')
const RecordingTextView = () => import('../views/RecordingTextView')
const TextManagerView = () => import('../views/TextManagerView')
const UploadedContentFileView = () => import('../views/UploadedContentFileView')
const ProcessContentFileView = () => import('../views/ProcessContentFileView')
const TextSetView = () => import('../views/TextSetView')
const NewRecordingView = () => import('../views/NewRecordingView')
const UserProfileView = () => import('../views/UserProfileView')
const ChangeEmailAddressView = () => import('../views/ChangeEmailAddressView')
const UserLanguagesView = () => import('../views/UserLanguagesView')
const SettingsView = () => import('../views/SettingsView')
const TextToSpeechView = () => import('../views/TextToSpeechView')
const TermsOfServiceView = () => import('../views/TermsOfServiceView')
const ShareTextSetView = () => import('../views/ShareTextSetView')
const AdminLatestRecordingsView = () => import('../views/AdminLatestRecordingsView')
const MicControlsView = () => import('../views/MicControlsView')
const AdminRecordingSetsView = () => import('../views/AdminRecordingSetsView')
const AdminSystemSettingsView = () => import('../views/AdminSystemSettingsView')
const AdminUserManagementView = () => import('../views/AdminUserManagementView')
const BalanceRecordingsView = () => import('../views/BalanceRecordingsView')
const CameraControlsView = () => import('../views/CameraControlsView')
const AnalyzeRecordingView = () => import('../views/AnalyzeRecordingView')
const SyncAudioVideoView = () => import('../views/SyncAudioVideoView')



Vue.use(Router)

//export default new Router({
let theRouter=new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landingpage',
      component: LandingPageView,
    },
    {
      path: '/termsofservice',
      name: 'termsofservice',
      component: TermsOfServiceView,
    },
    {
      path: '/login',
      component: TheContainer,
      name: 'login',
      props: {showHeader:false},
      beforeEnter: authGuard,
      children: [
        {
          path: '/ui/loggedin',
          name: 'loggedin',
          component: LoggedinView,
          beforeEnter: authGuard,
        },
      ],  
    },
    {
      path: '/ui',
      name: 'ui',
      component: TheContainer,
      props: {showHeader:true},
      beforeEnter: authGuard,
      children: [
        {
          path: '/ui/nopermission/:permissionId',
          name: 'nopermission',
          component: NoPermissionView, 
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/dashboard',
          name: 'dashboard',
          component: DashboardView, 
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordings',
          name: 'recordings',
          component: RecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/newrecording',
          name: 'newrecording',
          component: NewRecordingView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingset/:recordingSetKey',
          name: 'recordingset',
          component: RecordingSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingtext/:recordingSetKey/:inputTextKey',
          name: 'recordingtext',
          component: RecordingTextView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/recordingtext/:recordingSetKey',
          name: 'recordingtextstart',
          component: RecordingTextView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/textmanager',
          name: 'textmanager',
          component: TextManagerView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/uploadedcontentfile/:uploadedContentKey',
          name: 'uploadedcontentfile',
          component: UploadedContentFileView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/processcontentfile/:uploadedContentKey',
          name: 'processcontentfile',
          component: ProcessContentFileView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/textset/:textSetKey',
          name: 'textset',
          component: TextSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/userprofile',
          name: 'userprofile',
          component: UserProfileView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/changeemailaddress',
          name: 'changeemailaddress',
          component: ChangeEmailAddressView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/userlanguages',
          name: 'userlanguages',
          component: UserLanguagesView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/settings',
          name: 'settings',
          component: SettingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/texttospeech',
          name: 'texttospeech',
          component: TextToSpeechView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/sharetextset/:textSetKey',
          name: 'sharetextset',
          component: ShareTextSetView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminrecordingsets',
          name: 'adminrecordingsets',
          component: AdminRecordingSetsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminlatestrecordings',
          name: 'adminlatestrecordings',
          component: AdminLatestRecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/miccontrols',
          name: 'miccontrols',
          component: MicControlsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminsystemsettings',
          name: 'adminsystemsettings',
          component: AdminSystemSettingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/adminusermanagement',
          name: 'adminusermanagement',
          component: AdminUserManagementView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/balancerecordings',
          name: 'balancerecordings',
          component: BalanceRecordingsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/cameracontrols',
          name: 'cameracontrols',
          component: CameraControlsView,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/analyzerecording/:recordingKey',
          name: 'analyzerecording',
          component: AnalyzeRecordingView,
          props: true,
          beforeEnter: authGuard,
        },
        {
          path: '/ui/syncaudiovideo/:loadRecordingKey?/:loadMicrophoneDataKey?/:loadCameraDataKey?',
          name: 'syncaudiovideo',
          component: SyncAudioVideoView,
          props: true,
          beforeEnter: authGuard,
        },
      ]
    },
  ]
})

export default theRouter;

