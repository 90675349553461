import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import i18n, { defaultLocaleLanguage } from '../i18n'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    localeLanguage: defaultLocaleLanguage,
    userPermissions: [],
    waitModalDataList: [],
    waitModalClock:0,
    loginProcessState:false,
    selectedUserKey:null,
  },
  mutations: {
    updateLocaleLanguage(state, newLocale) {
      state.localeLanguage = newLocale
    },
    updateUserPermissions(state, newUserPermissions) {
      state.userPermissions = newUserPermissions
    },
    addWaitModalData(state, waitModalData) {
      var endTime=2000;
      if ('time' in waitModalData) {
        endTime=waitModalData.time;
      }
      endTime+=state.waitModalClock;
      let index=state.waitModalDataList.findIndex(element => element.text==waitModalData.text);
      if (index>=0) {
        state.waitModalDataList[index].requestCount++;
        if (state.waitModalDataList[index].endTime<endTime) {
          state.waitModalDataList[index].endTime=endTime;
        }
      } else {
        state.waitModalDataList.push({id:waitModalData.id,text:waitModalData.text,requestCount:1,startTime:state.waitModalClock,endTime:endTime})
      }
    },
    removeWaitModalData(state, id) {
      let index=state.waitModalDataList.findIndex(element => element.id==id);
      if (index>=0) {
        state.waitModalDataList[index].requestCount--;
        if (state.waitModalDataList[index].requestCount==0) {
          if (state.waitModalDataList.length>1) {
            //We have another wait in queue. Lets us keep the endtime(if longer) and starttime so the progress bar does not go backwards
            state.waitModalDataList[1].startTime=state.waitModalDataList[0].startTime;
            state.waitModalDataList[1].endTime=state.waitModalDataList[0].endTime;
          } 
          state.waitModalDataList.splice(index,1);
        }
      } else {
        console.trace("Could not find id:"+id)
      }
    },
    removeAllWaitModalData(state) {
      console.log("Removing all wait modals");
      state.waitModalDataList=[];
    },
    updateWaitModalClock(state, clockIncrease) {
      state.waitModalClock+=clockIncrease;
    },
    updateWaitModalTime(state, modalData) {
      let index=state.waitModalDataList.findIndex(element => element.id==modalData.id);
      //It can have been closed before we get here to update the time
      if (index>=0) {
        state.waitModalDataList[index].endTime+=modalData.addedTime;
      }
    },
    updateLoginProcessState(state, newState) {
      state.loginProcessState=newState;
    },
    updateSelectedUserKey(state,newKey) {
      state.selectedUserKey=newKey;
    },
  },
  actions: {
    changeLocaleLanguage({ commit }, newLocale) {
      i18n.locale = newLocale // important!
      commit('updateLocaleLanguage', newLocale)
    },
    setUserPermissions({ commit }, newUserPermissions) {
      commit('updateUserPermissions', newUserPermissions)
    },
    addWaitModal({ commit }, waitModalData) {
      let modalData=waitModalData;
      modalData["id"]=waitModalData.text;
      commit('addWaitModalData', modalData);
      return {id:waitModalData.text};
    },
    removeWaitModal({ commit }, promiseResultObject) {
      promiseResultObject.then(response => {
        commit('removeWaitModalData', response.id);
      });
    },
    removeAllWaitModals({ commit }) {
      commit('removeAllWaitModalData');
    },
    increaseWaitModalClock({ commit }, clockIncrease) {
      commit('updateWaitModalClock', clockIncrease);
    },
    addWaitModalEndTime({ commit }, waitModalData) {
      commit('updateWaitModalTime', waitModalData);
    },
    setLoginProcessState({commit}, newState) {
      commit('updateLoginProcessState', newState)
    },
    setSelectedUserKey({commit}, newKey) {
      commit('updateSelectedUserKey', newKey);
    },
  },
  getters: {
    hasPermission: (state) => (permissionId) => {
      return state.userPermissions.includes(permissionId)
    },
    getActiveWaitModalData: (state) => () => {
      if (state.waitModalDataList.length==0) {
        return null;
      } 
      return state.waitModalDataList[0];
    }, 
    getWaitModalClock: (state) => () => {
      return state.waitModalClock;
    },
    getLoginProcessState: (state) => () => {
      return state.loginProcessState;
    },
    getSelectedUserKey: (state) => () => {
      return state.selectedUserKey;
    },
  },

  plugins: [createPersistedState()]
})

