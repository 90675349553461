<template>
  <div @mousemove="userActivity()" @click="userActivity()" @keydown="userActivity()">
    <router-view></router-view>

    <CModal
      color="success"
      :show="$auth.loading"
      :closeOnBackdrop="false"
    >
      <template slot="header">
        <h5>{{$t('Please wait')}}</h5>
      </template>
      <CProgress
        :value="authwaitcounter"
        :max="authwaitend"
        animated
        color="primary"
      />
      <template slot="footer-wrapper">
        <div class="text-center p-3">
          {{$t("Authenticating user")}}
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>

  
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      authwaitcounter:1,
      authwaitend:5,
      inactivityLogoutTimeSeconds:36000,
    }
  },
  created: function () {
    this.$i18n.locale=this.$store.state.localeLanguage;
    this.$store.dispatch("removeAllWaitModals");
    this.startInactivityTimer();
  },
  methods: {
    startInactivityTimer() {
      //Call once every second
      setInterval(this.checkUserActivity.bind(this), 1000);
    },
    checkUserActivity() {
      this.$userIdleTime.seconds++; //I know there is a race condition here, but it does not matter since we have so many reset events so even if we miss one the next will reset the counter

      //Piggyback on this user activity timer for authwait progress bar
      if (this.$auth.loading) {
        //Add one to both counter and end so we are increasing the progress bar but will never get to end. Will give the impression to the user that something is happening and make the patient.
        //We also start at 1 to communicate the feeling that something has already happened in the background
        this.authwaitcounter++; 
        this.authwaitend++;
      } else {
        this.authwaitcounter=1;
        this.authwaitend=5;
      } 

      if (this.$userIdleTime.seconds>this.inactivityLogoutTimeSeconds) {
        this.$auth.logout({ returnTo: "https://lingotion.com" });
      }
    },
    userActivity() {
     this.$userIdleTime.seconds=0;
    },
  },
}
</script>

<style lang="scss">
  /* Import Main styles for this application*/
  @import 'assets/scss/style';
</style>

